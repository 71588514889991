// import bulmaCalendar from "../utilities/bulma/bulma-calendar"
import bulmaCalendar from "../utilities/bulma/bulma-calendar_6_1_18"
$(document).on(('turbo:load'), () => {
  if ($('#professional_course_start_date_of_event').length) {
    const date_selectable = function(year_index, month_index) {
      const current_users_group = document.getElementById('cycle_group_number').dataset.cycleGroupNumber;
      const current_date = new Date(year_index, month_index, 1);
      let calculation_number = 999999
      switch (current_users_group) {
        case '1':
          //calculation_number = (current_date.getFullYear() - 1999) % 3;
		  //temp adjustment
		  if (year_index >= 2024 && year_index <= 2026) {
			  calculation_number = 0;
		  }
          break;
        case '2':
          //calculation_number = (current_date.getFullYear() - 2001) % 3;
		  //temp adjustment
		  if (year_index >= 2023 && year_index <= 2025) {
			  calculation_number = 0;
		  }
          break;
        case '3':
          //calculation_number = (current_date.getFullYear() - 2000) % 3;	
		  //temp adjustment  
		  if (year_index >= 2025 && year_index <= 2027) {
			  calculation_number = 0;
		  }
          break;
      }
      return calculation_number === 0;
    }

    let default_date = new Date();
    let date_val = $("#professional_course_start_date_of_event").val();
    const editing = $('#editing_record')[0];
    if (!date_val) date_val = $("#professional_course_start_date_of_event")[0].getAttribute('value');
    if (!date_val) {
      const offset = default_date.getMonth() >= 6;
      for (let i = 0; i <= 3; i++) {
        if (date_selectable(offset ? default_date.getFullYear() + 1 : default_date.getFullYear(), default_date.getMonth())) {
          date_val = default_date;
        } else {
          default_date.setFullYear(default_date.getFullYear() + 1)
        }
      }
    }
    if (date_val.constructor.name === 'String') {
      const date = new Date();
      const split_date_string = date_val.split('-');
      date.setFullYear(split_date_string[0]);
      date.setMonth(split_date_string[1]);
      date.setDate(split_date_string[2]);
    }
    let offset = default_date.getTimezoneOffset()
    $("#professional_course_start_date_of_event").val(new Date(date_val + offset));
    let remote_offset = false;
    const date_local = new Date();
    if (editing) {
      const split_date_string = editing.dataset.courseStartDate.substring(0, editing.dataset.courseStartDate.search(' ')).split('-');
      date_local.setFullYear(split_date_string[0]);
      date_local.setMonth(split_date_string[1] - 1);
      date_local.setDate(split_date_string[2]);
      remote_offset = date_local.getMonth() >= 6;
      Array.from(document.getElementsByClassName('invalid_date_warning')).forEach((e) => e.classList.toggle('is-hidden', date_selectable(remote_offset ? date_local.getFullYear() + 1 : date_local.getFullYear(), date_local.getMonth())));
    }
    let options = {
      startDate: date_val,
      "color": "#000858",
      "dateFormat": "MM/dd/yyyy",
      "showHeader": false, 
      "showButtons": false, 
      "showFooter": false, 
      "showClearButton": false,
      disabledDates: function(date, day) {
        const calendar_date = new Date(day);
        const year_offset = calendar_date.getMonth() >= 6;
        if (editing && date_selectable(remote_offset ? date_local.getFullYear() + 1 : date_local.getFullYear(), date_local.getMonth())) {
          if (date_local.getFullYear() + (remote_offset ? 1 : 0) === calendar_date.getFullYear() + (year_offset ? 1 : 0)) {
            return !date_selectable(year_offset ? calendar_date.getFullYear() + 1 : calendar_date.getFullYear(), calendar_date.getMonth());
          } else {
            return true;
          }
        } else {
          return !date_selectable(year_offset ? calendar_date.getFullYear() + 1 : calendar_date.getFullYear(), calendar_date.getMonth());
        }
    }}
    const calendars = bulmaCalendar.attach('[type="date"]', options);

    var element = calendars[0];
    if (element) {
      $(".datetimepicker-clear-button").hide();
      element.on('select', function(datepicker) {
        $("#professional_course_start_date_of_event").trigger("change");
        $("#professional_course_start_date_of_event").val(datepicker.data.value());
        const split_date_string = datepicker.data.value().split('/');
        date_local.setFullYear(split_date_string[2]);
        date_local.setMonth(split_date_string[0] - 1);
        date_local.setDate(split_date_string[1]);
        const remote_offset = date_local.getMonth() >= 6;
        Array.from(document.getElementsByClassName('invalid_date_warning')).forEach((e) => e.classList.toggle('is-hidden', date_selectable(remote_offset ? date_local.getFullYear() + 1 : date_local.getFullYear(), date_local.getMonth())));
      });
    }
  }
})